/**
 * icon types = 'default', 'rounded', 'outline', 'sharp'
 *
 */

 module.exports = {
  theme: 'clockwork',
  config: {
    show_utility_bar: false
  },
  icons: {
    type: 'outline',
    custom: {
      /*ic_search: {
        viewbox: '0 0 32 32',
        content: '<path id="Fill 1" fill-rule="evenodd" clip-rule="evenodd" d="M3.2 14.4C3.2 8.224 8.224 3.2 14.4 3.2C20.5744 3.2 25.6 8.224 25.6 14.4C25.6 17.4752 24.3536 20.2624 22.3392 22.2896C22.3296 22.2992 22.3184 22.2992 22.3088 22.3088C22.3008 22.3184 22.2992 22.3296 22.2896 22.3392C20.2624 24.3536 17.4752 25.6 14.4 25.6C8.224 25.6 3.2 20.5744 3.2 14.4ZM31.5312 29.2688L25.64 23.3776C27.6128 20.9136 28.8 17.7952 28.8 14.4C28.8 6.4608 22.3408 0 14.4 0C6.4608 0 0 6.4608 0 14.4C0 22.3408 6.4608 28.8 14.4 28.8C17.7952 28.8 20.9136 27.6128 23.3776 25.64L29.2688 31.5312C29.5808 31.8432 29.9904 32 30.4 32C30.8096 32 31.2192 31.8432 31.5312 31.5312C32.1568 30.9056 32.1568 29.8944 31.5312 29.2688Z" fill="currentColor"/>'
      },*/
      ic_add_circle: {
        viewbox: '0 0 24 24',
        content: '<g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path fill="currentColor" fill-rule="nonzero" d="M12 1c6.075 0 11 4.925 11 11s-4.925 11-11 11S1 18.075 1 12 5.925 1 12 1zm0 2a9 9 0 10.001 18.001A9 9 0 0012 3zm0 4a1 1 0 011 1v3h3a1 1 0 01.993.883L17 12a1 1 0 01-1 1h-3v3a1 1 0 01-.883.993L12 17a1 1 0 01-1-1v-3H8a1 1 0 01-.993-.883L7 12a1 1 0 011-1h3V8a1 1 0 01.883-.993z"/></g>'
      },
      ic_chat_bubble: {
        viewbox: '0 0 24 24',
        content: '<g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path fill="currentColor" fill-rule="nonzero" d="M4.583 19.419l3.803-1.268c.252-.084.529-.064.767.056a7.38 7.38 0 003.348.793 7.502 7.502 0 006.708-4.15 7.386 7.386 0 00.793-3.347l.001-.448c-.21-3.807-3.25-6.846-7.001-7.055h-.503a7.384 7.384 0 00-3.35.795 7.501 7.501 0 00-4.147 6.708 7.378 7.378 0 00.792 3.346c.12.238.14.514.056.767L4.583 19.42zm17.419-7.92a9.38 9.38 0 01-1.006 4.248 9.496 9.496 0 01-12.36 4.429L3.318 21.95a1 1 0 01-1.265-1.265l1.773-5.318a9.364 9.364 0 01-.824-3.866 9.5 9.5 0 015.249-8.493A9.376 9.376 0 0112.5 2l.556.002c4.825.266 8.677 4.118 8.945 8.998v.499z"/></g>'
      },
      ic_collections: {
        viewbox: '0 0 24 24',
        content: '<g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path fill="currentColor" fill-rule="nonzero" d="M14 1c.043 0 .085.003.126.008L14 1a1.028 1.028 0 01.25.032l.03.008c.03.009.061.02.091.031l.027.012a.777.777 0 01.082.04l.032.018a.877.877 0 01.081.054l.02.015.012.01.082.073 6 6c.03.03.057.06.082.093l-.082-.093a.953.953 0 01.152.195l.018.032a.777.777 0 01.04.082l.012.027c.012.03.022.06.031.091l.008.03a.96.96 0 01.02.093l.004.031c.005.041.008.083.008.126v12a3 3 0 01-3 3H6a3 3 0 01-3-3V4a3 3 0 013-3zm-1 2H6a1 1 0 00-1 1v16a1 1 0 001 1h12a1 1 0 001-1V9h-5a1 1 0 01-.993-.883L13 8V3zm-1 8a1 1 0 011 1v2h2a1 1 0 01.993.883L16 15a1 1 0 01-1 1h-2v2a1 1 0 01-.883.993L12 19a1 1 0 01-1-1v-2H9a1 1 0 01-.993-.883L8 15a1 1 0 011-1h2v-2a1 1 0 01.883-.993zm3-6.585V7h2.586L15 4.415z"/></g>'
      },
      ic_download: {
        viewbox: '0 0 20 22',
        content: '<path d="M19 15a1 1 0 011 1v3a3 3 0 01-3 3H3a3 3 0 01-3-3v-3a1 1 0 012 0v3a1 1 0 001 1h14a1 1 0 001-1v-3a1 1 0 011-1zM10 0a1 1 0 01.993.883L11 1v11.585l2.293-2.292a1 1 0 011.32-.083l.094.083a1 1 0 01.083 1.32l-.083.094-4 4-.044.042-.068.055-.11.071-.114.054-.105.035-.118.025-.089.01h-.118l-.06-.006-.115-.02-.108-.033-.081-.034-.098-.052-.096-.067a.97.97 0 01-.09-.08l-4-4a1 1 0 011.32-1.497l.094.083L9 12.585V1a1 1 0 01.883-.993L10 0z" fill="currentColor" fill-rule="nonzero"/>'
      },
      ic_favorite_border: {
        viewbox: '0 0 24 24',
        content: '<g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path fill="currentColor" fill-rule="nonzero" d="M12.354 3.905a6.5 6.5 0 119.194 9.194l-8.84 8.84a1 1 0 01-1.414 0l-8.84-8.84a6.5 6.5 0 010-9.194 6.5 6.5 0 019.194 0l.353.353.353-.353zm-.353 15.913l7.073-7.073 1.06-1.06a4.5 4.5 0 10-6.366-6.366l-1.06 1.06a1 1 0 01-1.414 0l-1.06-1.06a4.501 4.501 0 10-6.366 6.366l8.133 8.133z"/></g>'
      },
      ic_logout: {
        viewbox: '0 0 24 24',
        content: '<g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path fill="currentColor" fill-rule="nonzero" d="M10 1a1 1 0 010 2H5a1 1 0 00-1 1v16a1 1 0 001 1h5a1 1 0 010 2H5a3 3 0 01-3-3V4a3 3 0 013-3zm6.293 6.293a1 1 0 011.414 0l4 4a.97.97 0 01.08.09l.017.022a1.2 1.2 0 01.05.074l.021.037c.011.02.022.04.031.06l.023.053.021.06.014.045.016.065.009.053c.007.048.011.097.011.148l-.004-.086.003.054L22 12v.034l-.004.051c-.001.022-.004.043-.007.064l-.01.052a.762.762 0 01-.015.065l-.014.046-.021.06-.023.051-.03.061-.022.037a.939.939 0 01-.147.186l.08-.09-.007.008-.073.082-4 4a1 1 0 01-1.414-1.414L18.585 13H9a1 1 0 01-.993-.883L8 12a1 1 0 011-1h9.585l-2.292-2.293a1 1 0 01-.083-1.32z"/></g>'
      },
      ic_person_outline: {
        viewbox: '0 0 24 24',
        content: '<g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path fill="currentColor" fill-rule="nonzero" d="M16 14a5 5 0 015 5v2a1 1 0 01-2 0v-2a3 3 0 00-3-3H8a3 3 0 00-3 3v2a1 1 0 01-2 0v-2a5 5 0 015-5zM12 2a5 5 0 11-.001 10.001A5 5 0 0112 2zm0 2a3 3 0 100 6 3 3 0 000-6z"/></g>'
      },
      ic_search: {
        viewbox: '0 0 24 24',
        content: '<defs><path id="a" d="M0 0h24v24H0z"/></defs><g fill="none" fill-rule="evenodd"><mask id="b" fill="#fff"><use xlink:href="#a"/></mask><path fill="currentColor" d="M2.4 10.8c0-4.632 3.768-8.4 8.4-8.4 4.63 0 8.4 3.768 8.4 8.4a8.37 8.37 0 01-2.446 5.917c-.007.007-.015.007-.022.015-.006.007-.008.015-.015.022A8.368 8.368 0 0110.8 19.2c-4.632 0-8.4-3.77-8.4-8.4m21.248 11.152l-4.418-4.419A10.736 10.736 0 0021.6 10.8C21.6 4.846 16.756 0 10.8 0 4.846 0 0 4.846 0 10.8c0 5.956 4.846 10.8 10.8 10.8 2.546 0 4.885-.89 6.733-2.37l4.419 4.418a1.195 1.195 0 001.696 0c.47-.469.47-1.227 0-1.696" mask="url(#b)"/></g>'
      },
      ic_share: {
        viewbox: '0 0 24 24',
        content: '<g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path fill="currentColor" fill-rule="nonzero" d="M18 1c2.21 0 4 1.79 4 4a3.999 3.999 0 01-6.841 2.815L9.85 10.912c.098.346.15.71.15 1.088 0 .377-.052.741-.15 1.087l5.312 3.095A3.999 3.999 0 0122 19c0 2.21-1.79 4-4 4a3.999 3.999 0 01-3.85-5.092l-5.309-3.093A3.999 3.999 0 012 12a3.999 3.999 0 016.841-2.815l5.309-3.097A3.999 3.999 0 0118 1zm0 16c-.695 0-1.308.355-1.666.894l-.05.1a.911.911 0 01-.065.097l-.033.066A2 2 0 1018 17zM6 10a2 2 0 101.716 3.027l.01-.02.024-.038c.16-.287.25-.618.25-.969a1.99 1.99 0 00-.254-.976l-.02-.03-.01-.017A1.99 1.99 0 006 10zm12-7a2 2 0 00-1.75 2.969l.024.037.01.017A2 2 0 1018 3z"/></g>'
      },
      ic_view_stream: {
        viewbox: '0 0 24 24', 
        content: '<path xmlns="http://www.w3.org/2000/svg" d="M3 2a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H3zm16 10a3 3 0 0 1 3 3v4a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3v-4a3 3 0 0 1 3-3h16zm0 2H3a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1zm0-14a3 3 0 0 1 3 3v4a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V3a3 3 0 0 1 3-3h16z" fill="currentColor"/>'
      },
      ic_view_module: {
        viewbox: '0 0 22 22',
        content: '<path xmlns="http://www.w3.org/2000/svg" d="M2 2v5h5V2H2zm17 9a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1h-7a1 1 0 0 1-1-1v-7a1 1 0 0 1 1-1h7zM8 11a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1v-7a1 1 0 0 1 1-1h7zm10 2h-5v5h5v-5zM7 13H2v5h5v-5zM8 0a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V1a1 1 0 0 1 1-1h7zm11 0a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1h-7a1 1 0 0 1-1-1V1a1 1 0 0 1 1-1h7zm-1 2h-5v5h5V2z" fill="currentColor"/>'
      }
    }
  }
}