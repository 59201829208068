export default {
  methods: {
    scrollToTop (elementName) {
      const adminNavEl = document.getElementById('page-admin-nav')
      const utilityNavEl = document.getElementById('page-utility-nav')
      const primaryNavEl = document.getElementById('page-primary-nav')

      const adminNavHeight = adminNavEl?.clientHeight ?? 0
      const utilityNavHeight = utilityNavEl?.clientHeight ?? 0
      const primaryNavHeight = primaryNavEl?.clientHeight ?? 0

      const top = adminNavHeight + utilityNavHeight + primaryNavHeight

      let el = null

      if (elementName.includes('.')) {
        el = document.getElementsByClassName(elementName.replace('.', ''))[0]
      } else if (elementName.includes('#')) {
        el = document.getElementById(elementName.replace('#', ''))
      } else {
        throw new Error('elementName should either be a class or an id.');
      }

      const position = this.getElementPosition(el)

      window.scrollTo({ top: position - top, behavior: 'auto' })
    },
    scrollToTopCMS () {
      window.scrollTo({ top: 0, behavior: 'auto' })
    },
    getElementPosition (obj) {
      let currentTop = 0

      if (obj.offsetParent) {
        do {
          currentTop += obj.offsetTop
        } while ((obj = obj.offsetParent))

        return currentTop
      }
    }
  }
}
