var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "footer-links" }, [
    _c(
      "div",
      { staticClass: "footer-row" },
      [
        _vm._l(_vm.footerLinksFiltered, function (link, index) {
          return [
            _c(
              "div",
              { key: index, staticClass: "footer-link" },
              [
                link.target === "_blank" ||
                link.href.includes("http") ||
                link.forceReload
                  ? _c(
                      "a",
                      {
                        staticClass: "link",
                        attrs: { href: link.href, target: link.target },
                      },
                      [
                        link.icon.url
                          ? _c("img", {
                              style: { width: link.icon.size },
                              attrs: { src: link.icon.url },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        !link.icon.url || !link.icon.show_icon_only
                          ? _c("span", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(link.label) +
                                  "\n          "
                              ),
                            ])
                          : _vm._e(),
                      ]
                    )
                  : _c(
                      "router-link",
                      {
                        staticClass: "link",
                        attrs: { to: _vm.$generateRoute(link.href) },
                      },
                      [
                        link.icon.url
                          ? _c("img", {
                              style: { width: link.icon.size },
                              attrs: { src: link.icon.url },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        !link.icon.url || !link.icon.show_icon_only
                          ? _c("span", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(link.label) +
                                  "\n          "
                              ),
                            ])
                          : _vm._e(),
                      ]
                    ),
                _vm._v(" "),
                _vm.footerLinksFiltered.length !== index + 1
                  ? _c("span", { staticClass: "pipe" }, [_vm._v(" | ")])
                  : _vm._e(),
              ],
              1
            ),
          ]
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }